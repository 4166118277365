import { BiBadgeCheck } from "react-icons/bi";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiDownload, FiHelpCircle, FiPlus, FiSearch } from "react-icons/fi";

import Button from "../../components/Button";
import ButtonWizard from "../../components/ButtonWizard";
import Modal from "../../components/Modal";

import Pill from "../../components/Pill";
import Loading from "../../components/Loading";
import Filter from "../../components/Filter";
import Input from "../../components/Input";

import Resident from "../../components/cells/Resident";

import {
  getResident,
  setSelected,
  deleteResident,
  residentReturnToBasic,
  setResidentFilter,
} from "../slices/resident";
import { toSentenceCase } from "../../utils";

import TemplateLocalStorage from "./components/TemplateLocalStorage";
import { post, getFile, getFileS3, get } from "../slice";
import {
  endpointResident,
  resident_statuses,
  resident_kyc_statuses,
  online_statuses,
  kyccolor,
  onboarding_statuses,
  endpointAdmin,
} from "../../settings";
import ModalWizardResident from "./components/ModalWizardResident";

const columns = [
  {
    Header: "Resident",
    accessor: (row) => <Resident id={row.id} data={row} />,
    sorting: "firstname",
  },
  {
    Header: "Building",
    accessor: (row) => row.building_name,
  },
  {
    Header: "Status",
    accessor: (row) => (
      <Pill color={row.status === "active" ? "success" : "secondary"}>
        {toSentenceCase(row.status)}
      </Pill>
    ),
    sorting: "onboarding",
  },
  {
    Header: "Onboarded",
    accessor: (row) => (
      <div className="flex items-center justify-center">
        <Pill color={row.onboarding === "yes" ? "success" : "danger"}>
          {toSentenceCase(row.onboarding)}
        </Pill>
      </div>
    ),
    sorting: "onboarding",
  },
  {
    Header: "Online",
    accessor: (row) => (
      <Pill color={row.online === "yes" ? "success" : "danger"}>
        {toSentenceCase(row.online)}
      </Pill>
    ),
    sorting: "online",
  },
  {
    Header: "Email",
    accessor: (row) => (
      <a target="_blank" rel="noopener noreferrer" href={"mailto:" + row.email}>
        {row.email}
      </a>
    ),
    sorting: "email",
  },
  {
    Header: "Phone",
    accessor: (row) => <h1>+{row.phone}</h1>,
    sorting: "phone",
  },
  {
    Header: "Resident Type",
    accessor: (row) => (
      <div className="capitalize">
        {" "}
        {row.resident_type == "premium" ? (
          <div className="flex gap-x-1 items-center bg-yellow-50 w-fit px-2 py-1 rounded-lg text-yellow-800">
            <BiBadgeCheck className="size-6" />
            Premium
          </div>
        ) : row.resident_type == "basic" ? (
          <div className="text-gray-400">Basic</div>
        ) : (
          "basic"
        )}
      </div>
    ),
    sorting: "resident_type",
  },
  {
    Header: "KYC Status",
    accessor: (row) =>
      row.status_kyc ? (
        <Pill color={kyccolor[row.status_kyc]}>
          {toSentenceCase(row.status_kyc)}
        </Pill>
      ) : (
        <Pill color="secondary">None</Pill>
      ),
    sorting: "status_kyc",
  },
];

function Component({ view, canAdd }) {
  let dispatch = useDispatch();
  let history = useHistory();
  let { url } = useRouteMatch();
  const { role, user } = useSelector((state) => state.auth);
  const { residentFilter } = useSelector((state) => state.resident);

  const {
    building_id,
    building_label,
    kyc_status,
    kyc_status_label,
    status,
    status_label,
    online_status,
    online_status_label,
    onboarding_status,
    onboarding_status_label,
    resident_type,
    resident_type_label,
  } = residentFilter;

  const [loading, setLoading] = useState(false);
  const [bulk, setBulk] = useState(false);
  const [downloadResident, setDownloadResident] = useState(false);
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [res, setRes] = useState();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(5);
  const [buildingList, setBuildingList] = useState("");
  const [openWizard, setOpenWizard] = useState(false);

  const residentTypes = [
    { label: "Basic", value: "basic" },
    { label: "Premium", value: "premium" },
  ];

  let fileInput = useRef();

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildingList(formatted);
          }
        )
      );
  }, [dispatch, search, limit]);

  useEffect(() => {
    let form = new FormData();
    form.append("resident", file);

    setData(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  return (
    <>
      {/* Start of Web Wizard */}
      <ModalWizardResident
        openWizard={openWizard}
        setOpenWizard={setOpenWizard}
      />
      {/* End of Web Wizard */}
      <Modal
        isOpen={bulk}
        toggle={() => {
          setBulk(false);
          setRes();
        }}
        title="Upload Bulk File"
        okLabel={res ? "OK" : "Submit"}
        disablePrimary={loading}
        disableSecondary={loading}
        onClick={
          res
            ? () => {
                setBulk(false);
                setRes();
              }
            : () => {
                setLoading(true);
                dispatch(
                  post(
                    endpointResident + "/management/resident/register/bulk",
                    data,
                    (res) => {
                      setLoading(false);

                      setRes(res.data.data);
                    },
                    (err) => {
                      setLoading(false);
                    }
                  )
                );
              }
        }
      >
        {res ? (
          <div>
            <p
              style={{
                color: "seagreen",
              }}
            >
              {res.data ? res.data.length : 0} rows{" "}
              {res.error === null ? "added succesfully." : "in correct format."}
            </p>
            <p
              style={{
                color: "crimson",
                marginBottom: 16,
              }}
            >
              {res.error ? res.error.length : 0} rows{" "}
              {res.error === null ? "failed to add." : "in wrong format."}
            </p>
            {res.error != null ? (
              res.error.map((el) => (
                <p
                  style={{
                    color: "crimson",
                    marginBottom: 4,
                  }}
                >
                  {el}
                </p>
              ))
            ) : (
              <p
                style={{
                  color: "seagreen",
                  marginBottom: 4,
                }}
              >
                {res.data.length} Resident(s) successfully added.
              </p>
            )}
          </div>
        ) : (
          <Loading loading={loading}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <input
                ref={fileInput}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => {
                  setFile(fileInput.current.files[0]);
                }}
              />
              <button
                onClick={() => {
                  setLoading(true);
                  dispatch(
                    getFileS3(
                      "https://yipy-assets.s3.ap-southeast-1.amazonaws.com/template/template_upload_resident_bulk.xlsx",
                      "template_upload_resident_bulk.xlsx",
                      (res) => {
                        setLoading(false);
                      }
                    )
                  );
                }}
                style={{
                  marginTop: 16,
                  color: "white",
                }}
              >
                Download Template
              </button>
            </div>
          </Loading>
        )}
      </Modal>
      <Modal
        isOpen={downloadResident}
        disableHeader={true}
        btnDanger
        onClick={() => {
          // dispatch(updateSetAsPaidSelectedDetail(multiActionRows));
          setDownloadResident(false);
        }}
        toggle={() => {
          setDownloadResident(false);
        }}
        okLabel={"Yes"}
        cancelLabel={"Cancel"}
      >
        This Feature is under development. Stay Tuned..
      </Modal>
      <TemplateLocalStorage
        view={view}
        pagetitle="Resident List"
        columns={columns}
        slice={"resident"}
        getAction={getResident}
        actions={
          role === "sa" || (role === "bm" && canAdd)
            ? [
                <Button
                  key="Add Resident"
                  label="Add Resident"
                  icon={<FiPlus />}
                  onClick={() => {
                    dispatch(setSelected({}));
                    history.push(url + "/add");
                  }}
                />,
                <Button
                  key="Add Resident Bulk"
                  label="Add Resident Bulk"
                  icon={<FiPlus />}
                  onClick={() => {
                    setBulk(true);
                  }}
                />,
              ]
            : null
        }
        actionDownloads={
          role === "sa" || (role === "bm" && canAdd)
            ? [
                <ButtonWizard
                  color="Download"
                  icon={<FiHelpCircle style={{ fontSize: "16px" }} />}
                  onClick={() => {
                    setOpenWizard(true);
                  }}
                />,
                <Button
                  fontWeight={500}
                  color="Download"
                  key="Download Data Resident"
                  label="Download Resident.csv"
                  icon={<FiDownload />}
                  onClick={() => {
                    setLoading(true);
                    dispatch(
                      getFile(
                        endpointResident +
                          "/management/resident/read/v2?export=true" +
                          "&onboarding=" +
                          onboarding_status +
                          "&building_id=" +
                          building_id +
                          "&resident_type=" +
                          resident_type +
                          "&page=" +
                          1 +
                          "&limit=" +
                          10000000,
                        "Data_Resident_Onboarding=" +
                          (onboarding_status
                            ? toSentenceCase(onboarding_status)
                            : "All") +
                          ".csv",
                        (res) => {
                          setLoading(false);
                        },
                        (err) => {
                          setLoading(false);
                        }
                      )
                    );
                  }}
                />,
              ]
            : null
        }
        deleteAction={deleteResident}
        returnBasicAction={view ? null : role === "sa" && residentReturnToBasic}
        filterVars={[
          status,
          kyc_status,
          online_status,
          onboarding_status,
          building_id,
          resident_type,
        ]}
        filters={[
          {
            hidex: building_id === "",
            label: "Building: ",
            value: building_id ? building_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  building_id: "",
                  building_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <>
                <Input
                  label="Search Building"
                  compact
                  icon={<FiSearch />}
                  inputValue={search}
                  setInputValue={setSearch}
                />
                <Filter
                  data={buildingList}
                  onClick={(el) => {
                    if (!el.value) {
                      setLimit(limit + el.restTotal);
                      return;
                    }
                    dispatch(
                      setResidentFilter({
                        ...residentFilter,
                        building_id: el.value,
                        building_label: el.label,
                      })
                    );
                    setLimit(5);
                    toggleModal(false);
                  }}
                  onClickAll={() => {
                    dispatch(
                      setResidentFilter({
                        ...residentFilter,
                        building_id: "",
                        building_label: "",
                      })
                    );
                    setLimit(5);
                    toggleModal(false);
                  }}
                />
              </>
            ),
          },
          {
            hidex: resident_type === "",
            label: "Resident Type: ",
            value: resident_type ? resident_type_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  resident_type: "",
                  resident_type_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={residentTypes}
                onClick={(el) => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      resident_type: el.value,
                      resident_type_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
                onClickAll={() => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      resident_type: "",
                      resident_type_label: "",
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: status === "",
            label: "Status: ",
            value: status ? status_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  status: "",
                  status_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={resident_statuses}
                onClickAll={() => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      status: "",
                      status_label: "",
                    })
                  );
                  toggleModal(false);
                }}
                onClick={(el) => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      status: el.value,
                      status_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: kyc_status === "",
            label: "KYC Status: ",
            value: kyc_status ? kyc_status_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  kyc_status: "",
                  kyc_status_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={resident_kyc_statuses}
                onClickAll={() => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      kyc_status: "",
                      kyc_status_label: "",
                    })
                  );
                  toggleModal(false);
                }}
                onClick={(el) => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      kyc_status: el.value,
                      kyc_status_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: online_status === "",
            label: "Online: ",
            value: online_status ? online_status_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  online_status: "",
                  online_status_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={online_statuses}
                onClickAll={() => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      online_status: "",
                      online_status_label: "",
                    })
                  );
                  toggleModal(false);
                }}
                onClick={(el) => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      online_status: el.value,
                      online_status_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: onboarding_status === "",
            label: "Onboarding: ",
            value: onboarding_status ? onboarding_status_label : "All",
            delete: () => {
              dispatch(
                setResidentFilter({
                  ...residentFilter,
                  onboarding_status: "",
                  onboarding_status_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={onboarding_statuses}
                onClickAll={() => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      onboarding_status: "",
                      onboarding_status_label: "",
                    })
                  );
                  toggleModal(false);
                }}
                onClick={(el) => {
                  dispatch(
                    setResidentFilter({
                      ...residentFilter,
                      onboarding_status: el.value,
                      onboarding_status_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
}

export default Component;
